import type { Item, Translations, PagedCollection } from 'shared/types'

export const isItem = <T extends Item>(data: any): data is T => '@id' in data

export const isPagedCollection = <T>(data: any): data is PagedCollection<T> => typeof data === 'object'
    && 'hydra:member' in data
    && Array.isArray(data['hydra:member'])

type Data = {
  '@id'?: string
  '@type'?: string
  id: number
  translations: Translations
}

export function taskToTaskItem(_data: Data, i = 0) {
  const data = { ..._data }
  delete data['@id']
  delete data['@type']
  const { translations, id, ...rest } = data

  return {
    ...rest,
    id,
    '@type': 'TaskGroupItem',
    position: i + 1,
    task: { id, translations },
  }
}

export const idToIri = (resource: string, id: number) => `/api/2.0/${resource}/${id.toString()}`

export const iriToId = (iri: string) => Number(iri.split('/').pop())

export const getId = (payload: Item) => iriToId(payload['@id'] || '')
