import {
  API_URL_TRAINING_WISH_FREE as freeWishUrl,
  API_URL_TRAINING_WISHES as wishUrl,
  API_URL_TRAINING_WISHES_PATHWAY as pathwayUrl,
} from 'constants/api'
import QueryClient from 'services/query'
import { mapFreeWishPayload, mapPayload } from 'services/trainingWish'
import { urlAddParams } from 'services/url'
import { getSearchFilters } from 'services/filters'

interface Params {
  id: string
  wishId: string
  lang: string
  from?: string
}

export const createWish = (client: QueryClient) => (
  async ({ params, request }: { params: Params, request: Request }) => {
    const { lang } = params
    const { id, wishId } = params
    const queryParams = new URLSearchParams(window.location.search)

    const formData = await request.formData()

    // Special case for training pathway
    const pathway = formData.get('pathway') === 'true'
    formData.delete('pathway')

    const filters = getSearchFilters()

    const payload = mapPayload({ formData, trainingId: Number(id) })

    try {
      const url = !!wishId ? `${wishUrl}/${wishId}` : (pathway ? pathwayUrl : wishUrl)
      await client.post(urlAddParams(url, filters), payload)
    } catch (e: Error | any) {
      return { error: e.response }
    }

    window.location.href = queryParams.get('from') ?? `/${lang}/needs-summary`

    return null
  }
)

export const createFreeWish = (client: QueryClient) => (
  async ({ params, request }: { params: Params, request: Request }) => {
    const { lang } = params
    const queryParams = new URLSearchParams(window.location.search)

    const filters = getSearchFilters()

    const formData = await request.formData()
    const payload = mapFreeWishPayload({ formData })

    try {
      await client.post(urlAddParams(freeWishUrl, filters), payload)
    } catch (e: Error | any) {
      return { error: e.response }
    }

    window.location.href = queryParams.get('from') ?? `/${lang}/needs-summary`

    return null
  }
)
