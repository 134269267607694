import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useNotification } from 'context/NotificationProvider'
import { useI18n } from 'context/I18nProvider'
import { useQuery } from 'context/QueryProvider'
import useSWR from 'hooks/swr'
import { useTableState } from 'hooks/antd'
import Button from 'components/UI/Button'
import Section from 'components/UI/Section'
import Spinner from 'components/Spinner'

import {
  API_URL_TRAINING_WISH_DETAILS,
  API_URL_TRAINING_WISH_PROVISION,
  API_URL_TRAINING_WISHES,
} from 'constants/api'

import Distribution from './Distribution'
import Header from './Header'
import UserSelect from './UserSelect'
import SubWishes from './SubWishes'

function AssignProvision() {
  const { i18n } = useI18n()
  const navigate = useNavigate()
  const { id } = useParams()
  const { notifyError, notifySuccess } = useNotification()
  const { client } = useQuery()

  const { current, pageSize, handleChange } = useTableState({ defaultPageSize: 20 })
  const pagination = {
    current,
    pageSize,
    handleChange,
  }

  const {
    data: trainingWish,
    isValidating: isWishValidating,
    mutate: mutateWish,
  } = useSWR(API_URL_TRAINING_WISH_DETAILS.replace('ID', id))
  const {
    data: wishes,
    isValidating: isChildrenValidating,
    mutate: mutateChildren,
  } = useSWR(`${API_URL_TRAINING_WISHES}?provision=${id}&page=${current}&nbpp=${pageSize}`)
  const isValidating = isWishValidating || isChildrenValidating
  const mutate = () => mutateWish().then(mutateChildren)

  const [users, setUsers] = useState([])
  const nbUsers = users ? users.length : 0

  const submit = async () => {
    try {
      await client.post(`${API_URL_TRAINING_WISH_PROVISION.replace('ID', id)}`, {
        users,
      })
      setUsers([])
      notifySuccess()
      await mutate()
    } catch (e) {
      notifyError(e)
    }
  }

  const changeInitial = async initial => {
    try {
      await client.patch(`${API_URL_TRAINING_WISHES}/${trainingWish.id}`, {
        initialProvisions: parseInt(initial, 10),
      })
      notifySuccess()
      await mutate()
    } catch (e) {
      notifyError(e)
    }
  }

  if (isValidating || !trainingWish || !wishes) {
    return <Section className="assign-provision" withBackBtn><Spinner /></Section>
  }

  return (
    <Section className="assign-provision" withBackBtn>
      <Header trainingWish={trainingWish} onUpdate={mutate} />
      <Distribution trainingWish={trainingWish} nbUsers={nbUsers} onInitialChange={changeInitial} />
      <SubWishes wishes={wishes} pagination={pagination} />

      <div className="w-full flex justify-between my-8">
        <UserSelect
          trainingWish={trainingWish}
          onChange={setUsers}
          value={users}
          onSubmit={submit}
          selected={wishes.results && wishes.results.map(u => u.username)}
        />
        <Button onClick={() => navigate(-1)}>
          {i18n('label.back')}
        </Button>
      </div>
    </Section>
  )
}

export default AssignProvision
