import * as React from 'react'
import { components } from 'react-select'
import type { ControlProps, InputProps, OptionProps } from 'react-select'
import { Tooltip } from 'antd'
import { useI18n } from 'context/I18nProvider'
import { selectModalProps } from 'utils/select'

export interface Value {
  label: string
  value: string|number
  draft?: boolean
}
interface CustomProps {
  selectProps: {
    noBorder?: boolean
    error?: boolean
    helper?: string
    tooltip?: boolean
  }
}

interface TrainingControlProps {
  children: React.ReactElement
}

export interface BaseSelectProps {
  component?: React.ElementType
  id?: number|string
  name?: string
  className?: string
  url?: string
  placeholder?: string
  helper?: string
  styles?: {
    option: (provided: any, state: any) => any
  } | {
    [key: string]: (styles: any) => any // utils/select
  }
  error?: boolean
  insideModal?: boolean
  outline?: boolean
  noBorder?: boolean
  required?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  apiPlatform?: boolean
  getOptionValue?: Function
  onChange?: Function
  mapper?: Function
  loadingMessage?: Function
  noOptionsMessage?: Function
  options?: Value[]
  defaultValue?: Value|Value[]|string|number|null
  value?: Value|Value[]|string[]|null
  ref?: any
}

const Control = ({ children, ...props }: ControlProps & TrainingControlProps & CustomProps) => {
  const labelCss = props.isFocused || props.hasValue
    ? '-top-[1.2rem] text-sm px-2 bg-white'
    : 'top-[.2rem] text-base px-0 bg-transparent'

  const borderWidth = props.selectProps.noBorder ? '' : '!border'
  const borderColor = props.selectProps.error ? '!border-red-500' : '!border-gray-300'

  const labelColor = props.selectProps.isDisabled ? 'text-gray-400' : 'text-gray-500'

  let labelText = props.selectProps.placeholder
  if (props.selectProps.required) labelText += ' *'

  return (
    <>
      <components.Control
        className={`w-full ${borderWidth} ${borderColor} flex flex-wrap relative`}
        {...props}
      >
        {children}
        {!!props.selectProps.placeholder && (
          <div
            className={`absolute max-w-full left-3 transition-all ${labelColor} ${labelCss}`}
          >
            <div className="relative top-[.4rem] truncate mr-0">
              {labelText}
            </div>
          </div>
        )}
      </components.Control>
      {!!props.selectProps.helper && (
        <div className="absolute top-10 text-gray-400 text-sm mt-1 ml-1">{props.selectProps.helper}</div>
      )}
    </>
  )
}

const Input = (props: InputProps) => (
  <components.Input
    id={props.id}
    inputClassName="text-gray-900"
    {...props}
  />
)

const Option = (props: OptionProps<Value> & CustomProps) => {
  const className = 'text-ellipsis overflow-hidden whitespace-nowrap'

  if (Boolean(props.selectProps?.tooltip)) {
    return (
      <Tooltip title={props.label}>
        <span className={props.data?.draft === true ? 'text-gray-400 italic' : undefined} title={props.label}>
          <components.Option className={className} {...props} />
        </span>
      </Tooltip>
    )
  }
  return (
    <span className={props.data?.draft === true ? 'text-gray-400 italic' : undefined} title={props.label}>
      <components.Option className={className} {...props} />
    </span>
  )
}

const IndicatorSeparator = () => <div />
const Placeholder = () => <div />

const grey = 'hsl(0, 0%, 80%)'
export const control = (base: any, state: ControlProps & CustomProps) => ({
  ...base,
  ...(state.selectProps.noBorder ? { backgroundColor: 'transparent' } : {}),
  borderColor: grey,
  borderWidth: state.selectProps.noBorder ? '0 0 1px 0' : (state.isFocused ? '2px' : '1px'),
  boxShadow: state.isFocused ? 0 : 0,
})

export const BaseSelect = React.forwardRef((props: BaseSelectProps, ref) => {
  const {
    styles: stylesProp,
    className,
    component,
    id,
    placeholder,
    error,
    insideModal,
    helper,
    outline = true,
    ...rest
  } = props

  const { i18n } = useI18n()
  const Component = component

  const modalProps = insideModal ? selectModalProps : { styles: {} }

  return (
    <div className={`relative flex w-full mt-1 ${className || ''}`}>
      {/* @ts-ignore */}
      <Component
        {...modalProps}
        className="w-full"
        // Be sure that menu is on top of label (z-10)
        classNames={{
          menu: () => '!z-[11]',
          // Be sure that portal is on top everything inside modals
          menuPortal: () => '!z-[2000]',
        }}
        styles={{
          clearIndicator: (styles: React.CSSProperties) => ({
            ...styles,
            padding: 0,
          }),
          control,
          indicatorSeparator: () => ({
            display: 'none',
          }),
          ...stylesProp,
          ...(modalProps?.styles || {}),
        }}
        inputId={id}
        placeholder={props.noBorder ? null : (placeholder || null)}
        components={{
          ...(outline ? { Control } : {}),
          IndicatorSeparator,
          Input,
          Option,
          Placeholder,
        }}
        control={control}
        error={error}
        closeMenuOnSelect={!props.isMulti}
        loadingMessage={() => i18n('global_searching')}
        noOptionsMessage={() => i18n('global_no_options')}
        menuPortalTarget={document.querySelector('body')}
        helper={helper}
        ref={ref}
        {...rest}
      />
    </div>
  )
})
