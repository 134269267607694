/* eslint-disable max-len */
import React from 'react'
import { createRoutesFromElements, Navigate, Outlet, Route } from 'react-router-dom'
import loadable from '@loadable/component'
import { createAccreditation } from 'actions/accreditations'
import { createCollective } from 'actions/collectives'
import { createDocument } from 'actions/documents'
import { login } from 'actions/login'
import { createOrder } from 'actions/orders'
import { createSession } from 'actions/sessions'
import { createFreeWish, createWish } from 'actions/wishes'
import { LoginDecorator } from 'containers/Login'
import AssignProvision from 'components/AssignProvision'
import CatalogNav from 'containers/Catalog/CatalogNav'
import ErrorPage from 'components/UI/ErrorPage'
import Page403 from 'components/Page403'
import Page404 from 'components/Page404'
import PrivateAppContainer from 'components/PrivateAppContainer'
import PrivateRoute from 'components/Auth/PrivateRoute'
import PublicAppContainer from 'components/PublicAppContainer'
import Redirect from 'components/Redirect'
import { RedirectUser } from 'containers/Dashboard'
import Spinner from 'components/Spinner'
import { API_TRAININGS_MANAGEMENT, API_TRAININGS } from 'constants/api-v2'
import { permissions as p } from 'constants/permissions'
import { loadAccreditation } from 'loaders/accreditations'
import { loadCollective } from 'loaders/collectives'
import { loadDocument, loadUserDocuments } from 'loaders/documents'
import { loadAdminProfile, loadSubProfile } from 'loaders/profiles'
import { loadAdminCertificate } from 'loaders/certificates'
import { loadUser } from 'loaders/user'
import { loadReminderUser, loadReminderRows } from 'loaders/reminders'
import {
  loadCustomFields,
  loadSession,
  loadSessionAndCustomFields,
  loadSessionPeriodForm,
  loadTab,
} from 'loaders/sessions'
import { loadSettings } from 'loaders/settings'
import { loadTraining, loadTrainingAndWish } from 'loaders/trainings'
import { sentryCreateBrowserRouter } from 'services/sentry'
import Matrix from './containers/Catalog/Matrix'

// The following will be lazy loaded:
const props = { fallback: <Spinner /> }
const AccreditationView = loadable(() => import('containers/Catalog/AccreditationView'), props)
const Accreditations = loadable(() => import('containers/Accreditations'), props)
const RenewableTrainings = loadable(() => import('containers/RenewableTrainings'), props)
const Admin = loadable(() => import('containers/Admin'), props)
const AdminProfilePermissions = loadable(() => import('containers/Admin/Profiles/New'), props)
const AdminSubProfilePermissions = loadable(() => import('containers/Admin/SubProfiles/New'), props)
const AdminCertificate = loadable(() => import('containers/Admin/Accreditation/Accreditations/Certificate/New'), props)
const Apps = loadable(() => import('containers/Apps'), props)
const Collectives = loadable(() => import('containers/Collective'), props)
const CollectiveForm = loadable(() => import('containers/Collective/Form'), props)
const Documents = loadable(() => import('containers/Documents'), props)
const DeletedWishes = loadable(() => import('containers/DeletedWishes'), props)
const Dashboard = loadable(() => import('containers/Dashboard'), props)
const FreeWishForm = loadable(() => import('containers/Catalog/FreeWishForm'), props)
const Login = loadable(() => import('containers/Login/Components/Login'), props)
const LoginRoutes = loadable(() => import('containers/Login'), props)
const MyPerimeter = loadable(() => import('containers/MyPerimeter'), props)
const NeedsSummary = loadable(() => import('containers/NeedsSummary'), props)
const PeriodEdit = loadable(() => import('containers/Session/Planning/Period/Edit'))
const PeriodNew = loadable(() => import('containers/Session/Planning/Period/New'))
const Reminders = loadable(() => import('containers/Reminders'), props)
const RemindersContent = loadable(() => import('containers/Reminders/Content'), props)
const Session = loadable(() => import('containers/Session'), props)
const SessionForm = loadable(() => import('containers/Session/Form'), props)
const Sessions = loadable(() => import('containers/Session/List'), props)
const Sso = loadable(() => import('containers/Sso'), props)
const Survey = loadable(() => import('containers/PublicSurvey'), props)
const TrainingHistory = loadable(() => import('containers/TrainingHistory'), props)
const TrainingView = loadable(() => import('containers/Catalog/TrainingView'), props)
const TrainingManagement = loadable(() => import('containers/TrainingManagement/Home'), props)
const TrainingForm = loadable(() => import('containers/TrainingManagement/Form'), props)
const TrainingPlan = loadable(() => import('containers/TrainingPlan'), props)
const WishForm = loadable(() => import('containers/Catalog/WishForm'), props)

/* eslint-disable react/prop-types */
function PublicErrorWrapper({ children }) {
  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[600px]">{children}</div>
    </div>
  )
}
/* eslint-enable react/prop-types */

export const router = ({ queryClient }) => sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      id="root"
      errorElement={<PublicErrorWrapper><ErrorPage /></PublicErrorWrapper>}
      loader={loadSettings(queryClient)}
    >
      {/* Old routes being redirected */}
      <Route index element={<Redirect path="home" />} />
      <Route exact path="/login" element={<Redirect path="login" />} />
      <Route exact path="/security/login-form" element={<Redirect path="login" />} />

      {/* Public routes (no profile, no sidebar) */}
      <Route
        path="/:lang/login"
        element={<PublicAppContainer><LoginDecorator><Login /></LoginDecorator></PublicAppContainer>}
        action={login(queryClient)}
      />
      <Route path="/:lang/login/*" element={<PublicAppContainer><LoginRoutes /></PublicAppContainer>} />
      <Route path="/:lang/survey/*" element={<PublicAppContainer><Survey /></PublicAppContainer>} />

      {/* SSO (from portal or training api) */}
      <Route exact path="/sso" element={<Sso />} />

      {/* Main app (with profile and sidebar) */}
      <Route
        id="user"
        path="/:lang"
        element={<PrivateAppContainer />}
        loader={loadUser(queryClient)}
        errorElement={<PrivateAppContainer><ErrorPage /></PrivateAppContainer>}
      >
        <Route index element={<Navigate to="home" replace />} />
        <Route path="apps" element={<Navigate to="home" replace />} />
        <Route path="home" element={<PrivateRoute><Apps /></PrivateRoute>} />

        {/* Accreditations */}
        <Route path="accreditations/*" element={<PrivateRoute permissions={p.accreditationBoard}><Accreditations /></PrivateRoute>} />

        {/* Renewable Trainings */}
        <Route path="renewable-trainings/*" element={<PrivateRoute permissions={p.renewableTrainingBoard}><RenewableTrainings /></PrivateRoute>} />

        {/* Admin */}
        <Route
          path="admin/profiles/:id"
          element={<PrivateRoute permissions={p.adminProfiles}><AdminProfilePermissions /></PrivateRoute>}
          loader={loadAdminProfile(queryClient)}
        />
        <Route
          path="admin/sub-profiles/:id"
          element={<PrivateRoute permissions={p.adminProfiles}><AdminSubProfilePermissions /></PrivateRoute>}
          loader={loadSubProfile(queryClient)}
        />
        <Route
          path="admin/accreditation/accreditations/:idAccreditation/certificate/:idCertificate"
          element={<PrivateRoute permissions={p.adminAccreditationManagement}><AdminCertificate /></PrivateRoute>}
          loader={loadAdminCertificate(queryClient)}
        />
        <Route path="admin/*" element={<PrivateRoute><Admin /></PrivateRoute>} />

        {/* Catalog */}
        <Route path="catalog/*" element={<PrivateRoute permissions={p.catalog}><Outlet /></PrivateRoute>}>

          <Route index element={<Redirect path="catalog/training" />} />
          {/* Catalog Tabs */}
          <Route path=":tab" element={<CatalogNav />} />
          {/* CatalogView */}
          <Route path="accreditation/:id" element={<AccreditationView />} />
          <Route path="matrix/*" element={<Matrix />} />
          <Route
            path="training/:id"
            element={<TrainingView />}
            loader={loadTraining(queryClient, API_TRAININGS)}
          />

          {/* WishForm */}
          <Route
            path="training/:id/wish/:wishId?"
            element={<WishForm />}
            action={createWish(queryClient)}
            loader={loadTrainingAndWish(queryClient)}
          />
          <Route
            path="accreditation/:id/wish"
            element={<WishForm />}
            action={createAccreditation(queryClient)}
            loader={loadAccreditation(queryClient)}
          />
          <Route
            path="training-wish/:wishId"
            element={<WishForm />}
            action={createWish(queryClient)}
            loader={loadTrainingAndWish(queryClient)}
          />
          <Route
            path="training/free-wish"
            element={<FreeWishForm />}
            action={createFreeWish(queryClient)}
            loader={() => ({ training: null, wish: null, trainingPlans: [] })}
          />
        </Route>

        {/* Collectives */}
        <Route
          path="collectives/new"
          element={<PrivateRoute permissions={p.collectives}><CollectiveForm /></PrivateRoute>}
          action={createCollective(queryClient)}
        />
        <Route
          path="collectives/:id/edit"
          element={<PrivateRoute permissions={p.collectives}><CollectiveForm /></PrivateRoute>}
          action={createCollective(queryClient)}
          loader={loadCollective(queryClient)}
        />
        <Route path="collectives/*" element={<PrivateRoute permissions={p.collectives}><Collectives /></PrivateRoute>} />
        <Route path="deleted-wishes/*" element={<PrivateRoute permissions={p.deletedWishes}><DeletedWishes /></PrivateRoute>} />

        <Route
          path="my-perimeter/*"
          element={<PrivateRoute permissions={p.myPerimeter}><MyPerimeter /></PrivateRoute>}
        />

        <Route
          path="training-history/*"
          element={<PrivateRoute permissions={p.trainingHistory}><TrainingHistory /></PrivateRoute>}
        />

        {/* Needs Summary */}
        <Route path="needs-summary/*" element={<PrivateRoute permissions={p.needsSummary}><Outlet /></PrivateRoute>}>
          <Route path="assign-provision/:id" element={<AssignProvision />} />
          <Route path=":allocation?" element={<NeedsSummary />} />
        </Route>

        {/* Document download */}
        <Route
          path="documents/:id"
          element={<PrivateRoute><Documents /></PrivateRoute>}
          loader={loadDocument(queryClient)}
        />

        {/* Dashboard */}
        <Route path="dashboard/*" element={<PrivateRoute><Outlet /></PrivateRoute>}>
          <Route index element={<RedirectUser />} />
          <Route
            path=":id"
            element={<Dashboard />}
            loader={loadUserDocuments(queryClient)}
            action={createDocument(queryClient)}
          />
        </Route>

        {/* Sessions */}
        <Route path="sessions/*" element={<PrivateRoute permissions={p.sessions}><Outlet /></PrivateRoute>}>
          <Route index element={<Sessions />} />
          <Route
            path="new"
            element={<SessionForm />}
            action={createSession(queryClient)}
            loader={loadCustomFields(queryClient)}
          />
          <Route
            path=":id/edit"
            element={<SessionForm />}
            action={createSession(queryClient)}
            loader={loadSessionAndCustomFields(queryClient)}
            exact
          />
          <Route
            id="session"
            path=":id/*"
            loader={loadSession(queryClient)}
          >
            <Route
              path=":tab?"
              element={<Session />}
              action={createOrder(queryClient)}
              loader={loadTab(queryClient)}
            />
          </Route>
        </Route>
        <Route
          path="sessions/:id/period/*"
          element={<PrivateRoute permissions={p.sessionView}><Outlet /></PrivateRoute>}
        >
          <Route path="new" element={<PeriodNew />} loader={loadSessionPeriodForm(queryClient)} />
          <Route path=":periodId" element={<PeriodEdit />} loader={loadSessionPeriodForm(queryClient)} />
        </Route>

        {/* REMINDERS */}
        <Route path="reminders" element={<PrivateRoute><Reminders /></PrivateRoute>} loader={loadReminderUser(queryClient)}>
          <Route path=":userId">
            <Route path=":scope" element={<RemindersContent />} loader={loadReminderRows(queryClient)} />
          </Route>
        </Route>
        <Route
          path="training-management"
          element={<PrivateRoute permissions={p.trainingManagement}><Outlet /></PrivateRoute>}
        >
          <Route index element={<TrainingManagement />} />
          <Route
            path="new"
            element={<TrainingForm />}
            loader={() => null}
          />
          <Route
            path=":id/edit"
            element={<TrainingForm />}
            loader={loadTraining(queryClient, API_TRAININGS_MANAGEMENT)}
          />
        </Route>

        {/* Training Plan */}
        <Route path="training-plan/*" element={<PrivateRoute permissions={p.trainingPlan}><Outlet /></PrivateRoute>}>
          <Route index element={<TrainingPlan />} />
          <Route path="assign-provision/:id" element={<AssignProvision />} />
        </Route>

        <Route path="403" element={<Page403 />} />
        <Route path="*" element={<PrivateRoute><Page404 /></PrivateRoute>} />
      </Route>
      <Route path="/404" element={<Page404 noSideBar />} />
    </Route>,
  ),
)
