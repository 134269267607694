import * as React from 'react'
import { useActionData, useLoaderData, useNavigation, useParams } from 'react-router-dom'
import { Table, Space } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import type { Document } from 'shared/types'
import { DeleteOutlined } from '@ant-design/icons'
import { useDeleteDocument } from 'hooks/Document/Delete'
import BinaryDownloadLink from 'components/Binary/BinaryDownloadLink'
import { useI18n } from 'context/I18nProvider'
import { useNotification } from 'context/NotificationProvider'
import { capitalize } from 'services/string'
import Spin from 'components/UI/Spin'
import Link from 'components/UI/Link'
import Button from 'components/UI/Button'

interface DocumentData {
  count: number
  items: Document[]
}

export function Documents() {
  const { documents } = useLoaderData() as { documents: DocumentData }
  const navigation = useNavigation()
  const { notifyError } = useNotification()
  const { lang } = useParams()

  const actionData = useActionData() as { error: object | null }
  const { error } = actionData || { error: null }

  const [isLoading, setLoading] = React.useState(false)

  if (error) notifyError(error)

  const { i18n, i18name } = useI18n()
  const deleteDocument = useDeleteDocument(setLoading)

  const columns: ColumnsType<Document> = [
    {
      title: i18n('label.label'),
      dataIndex: 'name',
      width: '30%',
      render: (text, document) => (
        <div className="text-[--primary] font-bold">
          <BinaryDownloadLink
            href={document.url}
            className="p-0 hover:text-gray-900"
          >
            {capitalize(text)}
          </BinaryDownloadLink>
        </div>
      ),
    },
    {
      title: i18n('label.deposit_date'),
      dataIndex: 'date',
      width: '20%',
      render: text => (
        <div className="text-gray-700">
          {dayjs(text).format('DD/MM/YYYY')}
        </div>
      ),
    },
    {
      title: i18n('label.origin'),
      dataIndex: 'session',
      width: '20%',
      render: data => (
        !!data
          ? <Link to={`/${lang}/sessions/${data.id}`}>{data.code}</Link>
          : i18n('documents.from-user')
      ),
    },
    {
      title: i18n('label.stage'),
      dataIndex: ['session', 'training'],
      width: '25%',
      render: data => (
        !!data ? <Link to={`/${lang}/catalog/training/${data.id}`}>{i18name(data)}</Link> : '-'
      ),
    },
    {
      title: '',
      width: '5%',
      render: (_, document) => (
        <Space size="middle">
          <Button icon={<DeleteOutlined />} onClick={() => deleteDocument(document)} type="text" />
        </Space>
      ),
    },
  ]

  if (isLoading || navigation.state === 'loading' || navigation.state === 'submitting') return <Spin />

  return (
    <Table
      rowKey="@id"
      dataSource={documents.items || []}
      columns={columns}
    />
  )
}
